<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Gspadrreport:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Gspadrreport:Save'">保存</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-form ref="basicForm" :model="dataSource" :rules="basicValidate">
                <el-tabs type="border-card" v-model.trim="activeName">
                    <el-tab-pane label="" name="tabbasic">
                        <el-collapse v-model.trim="collapseItem">
                            <el-collapse-item title="基本信息" name="basicinfo">
                                <el-form-item v-if="!config.isAdd">
                                    <el-col :span="3" class="form-title">单据号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.GspRecordNo" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="CustomerCode">
                                            <LookPopup v-model="dataSource.CustomerCode"
                                                dataTitle="货主选择"
                                                dataUrl='omsapi/customerinfo/getlist'
                                                syncField='CustomerCode'
                                                @syncFieldEvent="syncCustomerEvent"
                                                rules="basicValidate.CustomerCode"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.CustomerNo"
                                                suffix-icon="el-icon-search" readonly
                                            ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">货主名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.CustomerName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客商编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="VendorCode">
                                            <LookPopup v-model="dataSource.VendorCode"
                                                dataTitle="客商选择"
                                                dataUrl='omsapi/customervendor/getlist'
                                                syncField='VendorCode'
                                                @syncFieldEvent="syncVendorEvent"
                                                rules="basicValidate.VendorCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">客商名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.VendorName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNo">
                                            <LookPopup v-model="dataSource.ProductNo"
                                                dataTitle="商品选择"
                                                dataUrl='omsapi/product/getproductlist'
                                                syncField='ProductNo'
                                                @syncFieldEvent="syncProductEvent"
                                                rules="basicValidate.ProductNo"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.ProductNo" 
                                                suffix-icon="el-icon-search" readonly
                                                ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">商品中文名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductName" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">规格：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductSpec"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成厂家：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductCompany"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>批号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductBatchCode">
                                            <el-input v-model.trim="dataSource.ProductBatchCode"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNum">
                                            <el-input v-model.trim="dataSource.ProductNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">金额：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductMoney"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">包装单位：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductUnit"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">包装数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductUnitNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">质检员：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductInspector"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="患者信息" name="basicinfo1">
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>患者姓名：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientName">
                                            <el-input v-model.trim="dataSource.PatientName"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">患者性别：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientSex">
                                            <DictionarySelect v-model="dataSource.PatientSex" datType='Int' dicTypeCode="SexTypeCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">出生年月：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientBirthday">
                                            <!-- <el-input v-model.trim="dataSource.PatientBirthday"></el-input> -->
                                            <el-date-picker v-model.trim="dataSource.PatientBirthday" placeholder="选择日期"
                                                type="month">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">民族：</el-col>
                                    <el-col :span="9">
                                        <el-form-item  prop="PatientNation">
                                            <el-input v-model.trim="dataSource.PatientNation"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">体重：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientWeight">
                                            <el-input v-model.trim="dataSource.PatientWeight"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系方式：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientPhone">
                                            <el-input v-model.trim="dataSource.PatientPhone"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>用药开始时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientMedsBeginTime">
                                            <el-date-picker v-model.trim="dataSource.PatientMedsBeginTime" placeholder="选择日期"
                                                type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>用药结束时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientMedsEndTime">
                                            <el-date-picker v-model.trim="dataSource.PatientMedsEndTime" placeholder="选择日期"
                                                type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>用药原因：</el-col>
                                    <el-col :span="9">
                                        <el-form-item  prop="PatientMedsReason">
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.PatientMedsReason"
                                                rows="3"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">用法用量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.PatientMedsUsage"
                                                rows="3"
                                                :maxlength="200"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="不良反应信息" name="basicinfo2">
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>发生时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="PatientAdrTime">
                                            <el-date-picker v-model.trim="dataSource.PatientAdrTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>不良反应表现：</el-col>
                                    <el-col :span="21">
                                        <el-form-item prop="PatientAdrContent">
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.PatientAdrContent"
                                                rows="4"
                                                :maxlength="200"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="处理信息" name="basicinfo3">
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.ProcessTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">处理状态：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-select v-model="dataSource.ProcessStatus" :maxlength="200">
                                                <el-option v-for="item in processStatusList" 
                                                    :key="item.Value" 
                                                    :label="item.Label" 
                                                    :value="item.Value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.ProcessPerson"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.ProcessPerson"
                                                suffix-icon="el-icon-search" readonly
                                            ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理结果：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.ProcessContent"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">调查情况：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.ProcessResearch"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">制单人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.CreatorName"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">制单时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.CreateTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </el-form> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1','basicinfo2','basicinfo3'],
                basicValidate: {
                    CustomerCode:[{ required: true, message: '货主不能为空', trigger: 'blur' }],
                    VendorCode:[{ required: true, message: '客商不能为空', trigger: 'blur' }],
                    ProductNo:[{ required: true, message: '商品不能为空', trigger: 'blur' }],
                    ProductBatchCode:[{ required: true, message: '批号不能为空', trigger: 'blur' }],
                    ProductNum:[{ required: true, message: '数量不能为空', trigger: 'blur' }],
                    PatientName:[{ required: true, message: '患者姓名不能为空', trigger: 'blur' }],
                    PatientPhone:[{ required: true, message: '联系方式不能为空', trigger: 'blur' }],
                    PatientMedsBeginTime:[{ required: true, message: '用药开始时间不能为空', trigger: 'blur' }],
                    PatientMedsEndTime:[{ required: true, message: '用药结束时间不能为空', trigger: 'blur' }],
                    PatientMedsReason:[{ required: true, message: '用药原因不能为空', trigger: 'blur' }],
                    PatientAdrTime:[{ required: true, message: '发生时间不能为空', trigger: 'blur' }],
                    PatientAdrContent:[{ required: true, message: '不良反应表现不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
            this.initialBaseList();
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/gspadrreport/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            initialBaseList(){
                var _this = this;
                _this.$ajax.send("omsapi/base/getlistbyenumname", "get", {name:'GspProcessStatusCode'}, (data) => {
                    if(data.IsSuccess) 
                        _this.processStatusList=data.Result;
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/gspadrreport/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
        },
        components: {
        }
    }
</script>